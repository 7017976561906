<template>
  <div class="w-auto h-screen bg-gradient-to-b from-0ab2ff to-white">
    <!-- Header -->
    <div class="h-2/12 flex justify-evenly items-center">
      <!-- Img -->
      <div class="w-2/4">
        <img class="w-2/5" src="../../assets/letras/mundozac.png" alt="" />
      </div>
      <!-- Botones -->
      <div class="w-1/4">
        <div class="flex items-center justify-between">
          <button
            @click="goToConsejos"
            class="
              py-2
              px-4 
              invisible
              bg-FECB00
              text-white
              box-shadow
              text-shadow
              rounded-lg
            "
          >
            Consejos
          </button>
          <p @click="logOut" class="cursor-pointer" for="">Cerrar sesión</p>
        </div>
      </div>
    </div>
    <div class="h-2/12 flex items-center">
      <div class="w-1/6"></div>
      <div class="w-2/6 gap-4">
        <div class="flex items-center">
          <img class="mr-4" src="../../assets/iconos/hoja.svg" alt="" />
          <p class="text-white text-base text-shadow">Consejos</p>
        </div>
        <button
          @click="goToConsejos"
          class="
            py-2
            mt-4
            px-4
            bg-FECB00
            text-shadow
            box-shadow
            text-white
            rounded-lg
          "
        >
          Consejos
        </button>
      </div>
      <div class="w-3/6"></div>
    </div>

    <div class=" h-8/12 flex justify-center">
      <div class=" w-4/6 h-full">
        <div class="flex items-center box-shadow
              text-shadow
              rounded-lg bg-FECB00 py-1
              px-3 mb-4">
          <img class="mr-4 w-7" src="../../assets/iconos/hoja.svg" alt="" />
          <p class="text-white text-base text-shadow">Consejos</p>
        </div>
        <div class="flex justify-start">
          <p class="w-1/12">Titulo:</p>
          <input
            v-model="title"
            class="w-8/12 rounded-md border1px border-black"
            type="text"
            placeholder="Agregue un titulo"
          />
        </div>
        <br />
        <div class="flex flex-col h-8/12 ">
        
          <p>Descripción:</p>
          <div class="flex h-3/5">
            <div class="w-1/12 h-full"></div>
            <textarea
              v-model="text"
              class="w-11/12 rounded-md border1px border-black"
              type="text"
            />
          </div>
          <div class="flex justify-end mt-4">

            <button
              @click="addCard"
              class="py-1
              px-6
              bg-FECB00
              text-white
              box-shadow
              text-shadow
              rounded-lg"
            >
              Publicar
            </button>
          </div>
        </div>
        <br />
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import router from "../../router";

export default {
  data() {
    return {
      title: "",
      text: "",
    };
  },
  methods: {
    ...mapActions(["onCreateCard", "onDeleteCard"]),

    addCard() {
      this.title.trim();
      this.text.trim();
      if (this.title.length > 0 && this.text.length > 0) {
        this.onCreateCard({ title: this.title, text: this.text });
        (this.title = ""), (this.text = "");
      } else alert("falta un dato");
    },

    goToConsejos() {
      router.replace("/cms");
    },
    logOut() {
      localStorage.removeItem("isLogged");
      router.replace("/");
    },
  },
};
</script>

<style>
.border1px {
  border: 1px solid;
}
</style>

export default {
    state: {
        cardsLivingAme:[{}],
    },
    mutations: {
        setCardsLivinAme(state,cards){
            state.cardsLivingAme = cards
        },
        setOnlyOneCard(state,card){
            state.cardsLivingAme.unshift(card)
        },
        deleteOneCard(state,id){
            state.cardsLivingAme = state.cardsLivingAme.filter(card => card._id !== id)
        },
        setUpdateOneCard(state, { id, newCard }) {
            console.log(newCard);
            state.cardsLivingAme = state.cardsLivingAme.map(card => {
                if (card._id === id) {
                     return newCard
                } else {
                  return  card
                }
            })
        }
    },
    actions: {
        onGetCardsAmeApi({commit}){
            let myHeaders = new Headers();
            myHeaders.append("Access-Control-Allow-Origin",`*`);
            myHeaders.append( 'Content-Type', 'application/json')
            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            fetch(`https://mundodezac.com.mx/api/ame`, requestOptions)
            .then(response => response.json())
            .then((res) => {
                commit('setCardsLivinAme',res.cards)
            })
            .catch(error => console.log('error', error));
        }
    },
    getters:{
        getCardsAme : state => state.cardsLivingAme
    },
  }
  